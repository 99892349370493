<template>
  <div class="modal-body">
    <div class="charts-wrapper mt-4">
      <el-form ref="DeleteOrderStatus" :model="SetSignForm" style="width: 150%">
        <el-row :gutter="24">
          <el-col :span="6">
            <div class="grid-content space-around">
              <h5 class="d-inline mr-2 font-weight-bold" style="width: 100%">
                {{ $t("message.set_sign") }}
              </h5>
            </div>
          </el-col>
          <el-col :span="3">
            <div class="d-flex align-items-center justify-content-center">
              <el-input
                  v-model="SetSignForm.order_id"
                  style="width: 100%"
                  placeholder="Заказ"
                  clearable
              />
            </div>
          </el-col>
          <el-col :span="3">
            <div class="d-flex align-items-center justify-content-center">
              <el-input
                  v-model="SetSignForm.service_id"
                  style="width: 100%"
                  placeholder="Услуга"
                  clearable
              />
            </div>
          </el-col>
          <el-col :span="3">
            <div class="d-flex align-items-center justify-content-center">
              <el-select
                  v-model="SetSignForm.type"
                  style="width: 100%"
                  placeholder="тип"
                  clearable
              >
                <el-option
                    v-for="type in types"
                    :label="type.name"
                    :value="type.value"
                />
              </el-select>
            </div>
          </el-col>
          <el-col :span="3">
            <div class="d-flex align-items-center justify-content-center">
              <el-date-picker
                  v-model="SetSignForm.date"
                  style="width: 100%"
                  clearable
              />
            </div>
          </el-col>
          <el-col :span="3">
            <div class="d-flex align-items-center justify-content-center" >
              <el-time-picker
                  v-model="SetSignForm.time"
                  style="width: 100%"
                  clearable
              />
            </div>
          </el-col>
          <el-col :span="3">
            <el-button
                type="success"
                size="medium"
                class="ml-4"
                @click="SetSign"
                icon="el-icon-download"
                :loading="loadingButton2"
                :disabled="loadingButton2 ? true : false"
                style="width: 100%"
            >
              Выполнить
            </el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="charts-wrapper mt-3">
      <el-form ref="DeleteOrderStatus" :model="SetHeaderFooterForm" style="width: 150%">
        <el-row :gutter="24">
          <el-col :span="9">
            <div class="grid-content space-around">
              <h5 class="d-inline mr-2 font-weight-bold" style="width: 300px">
                {{ $t("message.set_header_footer") }}
              </h5>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="text-center d-flex align-items-right justify-content-center ">
              <el-input
                  v-model="SetHeaderFooterForm.order_id"
                  style="width: 100%"
                  placeholder="Заказ"
                  clearable
              />
            </div>
          </el-col>
          <el-col :span="4">
            <div class="text-center d-flex align-items-right justify-content-center ">
              <el-input
                  v-model="SetHeaderFooterForm.service_id"
                  style="width: 100%"
                  placeholder="Услуга"
                  clearable
              />
            </div>
          </el-col>
          <el-col :span="4">
            <div class="text-center d-flex align-items-right justify-content-center ">
              <el-select
                  v-model="SetHeaderFooterForm.type"
                  style="width: 100%"
                  placeholder="тип"
                  clearable
              >
                <el-option
                  v-for="type in types"
                  :label="type.name"
                  :value="type.value"
                />
              </el-select>
            </div>
          </el-col>
          <el-col :span="3">
            <el-button
                type="success"
                size="medium"
                class="ml-4"
                @click="SetHeaderFooter"
                icon="el-icon-download"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                style="width: 100%"
            >
              Выполнить
            </el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="charts-wrapper mt-3">
      <el-row :gutter="24">
        <el-col :span="9">
          <div class="grid-content space-around">
            <h5 class="d-inline mr-2 font-weight-bold" style="width: 300px">
              {{ $t("message.bonus_mode") }}
            </h5>
          </div>
        </el-col>
        <el-col :span="6">
          <el-select
            v-model="BonusModeForm.mode"
            style="width: 100%"
          >
            <el-option
              v-for="mode in BonusModeForm.modes"
              :label="mode.name"
              :value="mode.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-button
              type="success"
              size="medium"
              class="ml-4"
              @click="SetBonusMode"
              icon="el-icon-download"
              :loading="loadingButton"
              :disabled="loadingButton ? true : false"
              style="width: 100%"
          >
            Выполнить
          </el-button>
        </el-col>
      </el-row>
    </div>
    <div class="charts-wrapper mt-3">
      <el-row :gutter="24">
        <el-col :span="9">
          <div class="grid-content space-around">
            <h5 class="d-inline mr-2 font-weight-bold" style="width: 300px">
              {{ $t("message.print_qr") }}
            </h5>
          </div>
        </el-col>
        <el-col :span="6">
          <el-select
              v-model="PrintQrForm.mode"
              style="width: 100%"
          >
            <el-option
                v-for="mode in PrintQrForm.modes"
                :label="mode.name"
                :value="mode.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-button
              type="success"
              size="medium"
              class="ml-4"
              @click="SetPrintQrMode"
              icon="el-icon-download"
              :loading="loadingButton"
              :disabled="loadingButton ? true : false"
              style="width: 100%"
          >
            Выполнить
          </el-button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { i18n } from "@/utils/i18n";
import axios from "axios";
import moment from "moment";
export default {
  name: "Home",
  data() {
    return {
      DeleteOrderStatusForm: {
        order_id: null,
        service_id: null,
        type: 'ambulatory',
      },
      BonusModeForm: {
        mode: null,
        modes: [
          {
            name: i18n.t('message.on_payment'),
            value: 'on_payment'
          },
          {
            name: i18n.t('message.on_close'),
            value: 'on_close'
          },
        ]
      },
      PrintQrForm: {
        enable: true,
        modes: [
          {
            name: i18n.t('message.enable'),
            value: 'enabled'
          },
          {
            name: i18n.t('message.disable'),
            value: 'disabled'
          }
        ]
      },
      SetSignForm: {
        order_id: null,
        service_id: null,
        type: 'ambulatory',
        date: "",
        time: "",
      },
      types: [
        {
          name: i18n.t("message.ambulatory"),
          value: 'ambulatory'
        },
        {
          name: i18n.t("message.stationary"),
          value: 'stationary'
        },
      ],
      SetHeaderFooterForm: {
        order_id: null,
        service_id: null,
        type: 'ambulatory',
      },
      loadingButton: false,
      loadingButton2: false,
      loadingButton3: false,
    };
  },
  methods: {
    async SetSign() {
      try {
        let { date, time, order_id, service_id } = this.SetSignForm;

        date = date ? moment(date).format("DD-MM-YYYY") : null;
        time = time ? moment(time).format('HH:mm') : null;

        let queryParams = [];
        if (date) queryParams.push(`date=${date}${time ? ' ' + time : ''}`);

        const url = `${process.env.VUE_APP_URL_DOCS}/set_sign/${order_id}/${service_id}${
            queryParams.length ? `?${queryParams.join('&')}` : ''
        }`;

        const response = await axios.get(url);

        this.$alert(response.data);
      } catch (error) {
        console.error("Ошибка при отправке запроса:", error);
      }
    },
    async SetHeaderFooter() {
      try {
        const { order_id, service_id, type } = this.SetHeaderFooterForm;
        const url = `${process.env.VUE_APP_URL_DOCS}/set_header_footer/${type}?order_id=${order_id}&service_id=${service_id}`;
        const response = await axios.get(url);

        this.$alert(response.data);
      } catch (error) {
        console.error("Ошибка при отправке запроса:", error);
      }
    },
    async SetBonusMode() {
      try {
        const { mode } = this.BonusModeForm;
        const url = `${process.env.VUE_APP_URL_DOCS}/set_setting/bonus_creation_mode/${mode}`;
        const response = await axios.get(url);

        this.$alert(response.data.message);
      } catch (error) {
        console.error("Ошибка при отправке запроса:", error);
      }
    },
    async SetPrintQrMode() {
      try {
        const { mode } = this.PrintQrForm;
        const url = `${process.env.VUE_APP_URL_DOCS}/set_setting/print_qr_mode/${mode}`;
        const response = await axios.get(url);

        this.$alert(response.data.message);
      } catch (error) {
        console.error("Ошибка при отправке запроса:", error);
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.top-status {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 24px;
}

.charts-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}

.statistic-info-wrap,
.leads-by-source {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
}

@media screen and (max-width: 1440px) {
  .leads-by-source {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media screen and(max-width: 576px) {
  .leads-by-source {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}

.chart-card {
  box-shadow: 0px 2px 5px 2px rgba($color: #303030, $alpha: 0.05);

}

.list {
  padding: 0;
  margin: 0;
  list-style: none;

  // list__item
  &__item {
    padding: 12px;
    border-radius: 8px;
    display: flex;
    gap: 12px;
    align-items: center;
    background: #fff;
    box-shadow: 0px 2px 5px 2px rgba($color: #303030, $alpha: 0.05);

    // list__item-icon
    &-icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 36px;
      height: 36px;
      border-radius: 10px;
      background: #3b82f6;
      color: #fff;
      font-size: 18px;
    }

    // list__item-total
    &-total {
      font-size: 18px;
      font-weight: 600;
      color: #333;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    // list__item-percent
    &-percent {
      font-size: 14px;
      font-weight: 500;
      color: #10b981;

      &.loss {
        color: #f43f5e;
      }
    }

    // list__item-subtitle
    &-subtitle {
      font-size: 14px;
      font-weight: 400;
      color: #bfbfd1;
      line-height: 10px;
    }
  }
}
</style>

<style lang="scss">
.top-collectors {
  margin-bottom: 1.5rem;
}

.el-progress__text {
  margin: 10px 0;
  opacity: 0.8;
}

.el-progress {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.mychart {
  border-radius: 10px;
}

.chrttext {
  color: #1e75a8;
  font-size: 19px;
  margin-bottom: 15px;
  font-weight: 700;
}

.charminitext {
  font-size: 13px;
  margin-left: 5px !important;
  color: #a2a7a2;
  font-weight: 500;
}

.home_link-pages i {
  border-radius: 5px;
  height: 70px;
  width: 70px;
  height: 7vw;
  width: 7vw;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.68s ease-out;
  transition: all 0.68s ease-out;
}

.bacg1 i {
  color: #40c9c6;
}

.bacg1:hover i {
  color: #ffffff;
  background-color: #40c9c6;
}

.bacg2 i {
  color: #8e55c7;
}

.bacg2:hover i {
  color: #ffffff;
  background-color: #8e55c7;
}

.bacg3 i {
  color: #f4516c;
}

.bacg3:hover i {
  color: #ffffff;
  background-color: #f4516c;
}

.bacg4 i {
  color: #36a3f7;
}

.bacg4:hover i {
  color: #ffffff;
  background-color: #36a3f7;
}

.bacg1 .text_icon>div {
  font-size: 28px;
  color: #40c9c6;
}

.bacg2 .text_icon>div {
  font-size: 28px;
  color: #8e55c7;
}

.click_link:hover .itme_blocks_pages {
  text-decoration: none;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.click_link .itme_blocks_pages {
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
}

.itme_blocks_pages {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 10px;
  min-height: 160px;
}

.itme_blocks_pages_botton {
  .itme_blocks_pages_botton_summa {
    line-height: 2rem;
    font-weight: 700;
    color: #303030;
    font-size: 16px;
  }

  .itme_blocks_pages_botton_title {
    color: #718096;
    font-size: 16px;
  }
}

.itme_blocks_pages_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info_topright {
  width: 40px;
  height: 25px;
  border-radius: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #91c714;
}

.iconstyle {
  width: 40px;
  height: 40px;
  transition: 0.7s;
  display: inline-block;
}

a:hover {
  text-decoration: none;
}
</style>
